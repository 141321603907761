import { Box, Container, Divider, Hidden } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { Layout } from 'src/components';
import UseInstallments from 'src/components/Landing/UseInstallments';
import LinearCTAButton from 'src/components/LinearCTAButton';
import JulyAppDownload from 'src/components/Merchant/Campaign/2022AAA_202211/JulyAppDownload';
import SectionCampaignFooter from 'src/components/Merchant/Campaign/TravelsSummerVol1/SectionCampaignFooter';
import { SITE_METADATA } from 'src/constants';
import styles from 'src/styles/pages/campaign202304_6pay.module.scss';
import PaidyLogoImg from 'src/images/merchant/campaign/202304_6pay/paidy-logo.png';
import BannerImg from 'src/images/merchant/campaign/202304_6pay/banner.png';
import BannerSpImg from 'src/images/merchant/campaign/202304_6pay/banner-sp.png';
import SixPayUpsellImg from 'src/images/merchant/campaign/202304_6pay/6pay_upsell.png';
import QrAndBadgesSwitch from 'src/components/QrAndBadgesSwitch';
import CartImg from 'src/images/6pay/cart.png';
import PayStep3Img from 'src/images/6pay/pay-step-3.png';
import PayStep4Img from 'src/images/6pay/pay-step-4.png';
import SectionMerchantGrid from 'src/components/Merchant/Campaign/202304_6pay/SectionMerchantGrid';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import classNames from 'classnames';
import { Link } from 'gatsby';
import throttle from 'lodash.throttle';
import Slider from 'react-slick';

const SEOProps = {
  title: SITE_METADATA.campaign_202304_6pay.title,
  description: SITE_METADATA.campaign_202304_6pay.description,
};

const LOGOS = [...Array(11).keys()].map((key) => ({
  key,
  // eslint-disable-next-line import/no-dynamic-require, security/detect-non-literal-require
  src: require(`src/images/merchant/campaign/202304_6pay/merchant-logos-header/merchant-${key}.png`),
}));

const NAVS = ['キャンペーン概要', '６回あと払い', '対象ショップ'];

const TERMS = [
  {
    title: 'キャンペーン対象',
    description:
      '対象ショップで初めて「あと払い（ペイディ）」の６回あと払いをご利用のお客様',
  },
  {
    title: 'キャンペーン概要・特典',
    description: (
      <>
        キャンペーン期間中に対象ショップの決済画面で「あと払い（ペイディ）」を選択し、初めて分割手数料無料*の６回あと払いで1回あたり6,000円以上購入すると、500円をキャッシュバック。最大3店舗（1,500円キャッシュバック）まで対象となります。
        <br />
        <br />
        なお、下記の4店舗のみ、一括払いで購入後、ペイディアプリから６回あと払いに変更したお支払いもキャンペーン対象となります。その際、キャンペーン期間中に必ずペイディアプリから6回あと払いに変更してください。
        <br />
        <br />
        ・UNDER ARMOUR（アンダーアーマー）
        <br />
        ・17LIVE
        <br />
        ・dazzy store
        <br />
        ・DRW
        <br />
        <br />
        上記の4店舗以外は、一括払いで購入後、ペイディアプリから６回あと払いに変更した場合はキャンペーン対象外となりますのでご注意ください。
      </>
    ),
  },
  {
    title: 'キャンペーン期間',
    description: '2023年4月24日 (月) 0:00～5月31日(水) 23:59',
  },
];

const SIXPAY = [
  {
    ImgComponent: () => <QrAndBadgesSwitch />,
    figCaption: 'ペイディアプリから本人確認する。',
  },
  {
    ImgComponent: () => (
      <img alt="cart" src={CartImg} width={184} height={184} />
    ),
    figCaption: 'ショップで欲しい商品をカートに入れる。',
  },
  {
    ImgComponent: () => (
      <img alt="pay-step-3" src={PayStep3Img} width={184} height={184} />
    ),
    figCaption:
      'お支払い方法で「あと払い（ペイディ）」を選択して、メールアドレスと携帯電話番号を入力する。',
  },
  {
    ImgComponent: () => (
      <img alt="pay-step-4" src={PayStep4Img} width={184} height={184} />
    ),
    figCaption: 'ご希望のお支払い回数を選んで完了。',
  },
];

const useScrollAndSetActiveSection = (navs = NAVS) => {
  const [activeSection, setActiveSection] = useState(navs[0]);

  const onScroll = useCallback(() => {
    if (!document) {
      return;
    }

    // eslint-disable-next-line no-restricted-syntax, no-plusplus
    for (let i = 0; i < navs.length; i++) {
      const nav = navs[i];
      const rect = document.getElementById(nav).getBoundingClientRect();
      const isOnScreen = rect.top <= 64 && rect.bottom >= 64;

      if (isOnScreen) {
        setActiveSection(nav);
      }
    }
  }, [navs]);

  useEffect(() => {
    const throttledScroll = throttle(onScroll, 200);

    if (window) {
      window.addEventListener('scroll', throttledScroll, true);
    }

    return () => {
      if (window) {
        window.removeEventListener('scroll', throttledScroll);
      }
    };
  }, [onScroll]);

  return activeSection;
};

const SLIDER_SETTINGS = {
  classNames: 'slider variable-width',
  dots: false,
  infinite: true,
  centerPadding: '2px',
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 2000,
  cssEase: 'linear',
  variableWidth: true,
};

export default function Campaign2023046pay() {
  const activeSection = useScrollAndSetActiveSection(NAVS);

  return (
    <Layout SEOProps={SEOProps} hasNoHeader hasNoFooter>
      <Box className={styles.page}>
        <header className={styles.sectionHeader}>
          <img alt="paidy-logo" width={114} height={45} src={PaidyLogoImg} />
        </header>

        <section className={styles.sectionBanner}>
          <Hidden xsDown>
            <img alt="banner" height={445} width={1440} src={BannerImg} />
          </Hidden>
          <Hidden smUp>
            <img alt="banner-sp" width="100%" src={BannerSpImg} />
          </Hidden>
        </section>

        <section className={styles.sectionMerchantLogos}>
          <Hidden xsDown>
            <p className={styles.title}>対象加盟店一例</p>
            <Box className={styles.rows}>
              <Box
                display="flex"
                flex={1}
                justifyContent="center"
                flexWrap="wrap"
              >
                {LOGOS.slice(0, 5).map((logo) => (
                  <Box key={logo.key}>
                    <img
                      alt={logo.key}
                      src={logo.src}
                      width={152}
                      height={50}
                    />
                  </Box>
                ))}
              </Box>
              <Box
                display="flex"
                flex={1}
                justifyContent="center"
                marginTop={3}
                flexWrap="wrap"
              >
                {LOGOS.slice(5).map((logo) => (
                  <Box key={logo.key}>
                    <img
                      alt={logo.key}
                      src={logo.src}
                      width={152}
                      height={50}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </Hidden>

          <Hidden smUp>
            <Box width="100%" height={80}>
              <Slider {...SLIDER_SETTINGS}>
                {LOGOS.map((logo) => (
                  <div style={{ width: 140 }}>
                    <img
                      alt={logo.key}
                      src={logo.src}
                      width={140}
                      height={46}
                    />
                  </div>
                ))}
              </Slider>
            </Box>
          </Hidden>
          <LinearCTAButton
            text="対象ショップを見る"
            to={`#${NAVS[2]}`}
            className={styles.button}
          />
        </section>

        <nav className={styles.sectionNav}>
          {NAVS.map((nav) => (
            <Box
              key={nav}
              className={classNames(styles.item, {
                [styles.active]: nav === activeSection,
              })}
            >
              <Link to={`#${nav}`}>{nav}</Link>
            </Box>
          ))}
        </nav>

        <section className={styles.sectionTerms} id={NAVS[0]}>
          <Container className={styles.container}>
            {TERMS.map((term) => (
              <Box key={term.title}>
                <p className={styles.title}>{term.title}</p>
                <p className={styles.description}>{term.description}</p>
              </Box>
            ))}
            <Box className={styles.rowDivider}>
              <Divider className={styles.divider} />
            </Box>
            <p className={styles.notes}>
              *口座振替・銀行振込のみ無料
              <br />
              ※お一人様につき1店舗1回まで。
              <br />
              ※６回あと払いのご利用には、ペイディアプリから本人確認が必要です。また、６回あと払いは1回のお買い物につき6,000円以上のお支払いでご利用いただけます。
              <br />
              ※一括払い、3回あと払い、12回あと払いはキャンペーン対象外となります。
              <br />
              キャンペーン終了後、6月30日または11月30日に特典分がキャッシュバックされる予定です。
              <br />
              キャッシュバックはペイディアプリおよびMyPaidyに反映されます。決済画面には反映されません。
            </p>
          </Container>
        </section>

        <section className={styles.section6Pay} id={NAVS[1]}>
          <Box className={styles.top}>
            <>
              <Hidden xsDown>
                <img
                  alt="6pay"
                  width={367}
                  height={400}
                  src={SixPayUpsellImg}
                />
              </Hidden>
              <Hidden smUp>
                <img
                  alt="6pay"
                  width={247}
                  height={268}
                  src={SixPayUpsellImg}
                />
              </Hidden>
            </>
            <Box className={styles.texts}>
              <p className={styles.title}>
                高額商品も、分割手数料無料*の
                <Hidden smUp>
                  <br />
                </Hidden>
                ６回あと払いでかしこく購入。
                <Hidden smUp>
                  <br />
                </Hidden>
                キャッシュバックでお得に 始める
                <Hidden smUp>
                  <br />
                </Hidden>
                チャンス！
              </p>
              <p className={styles.text}>
                ペイディなら、６回あと払いが分割手数料無料*。お支払い総額はそのまま、月々のお支払い金額を調整できるので、高額商品もかしこく購入できます。
                このチャンスに、６回あと払いを利用してお得にお買い物を楽しみませんか？
              </p>
              <p className={styles.notes}>*口座振替・銀行振込のみ無料</p>
            </Box>
          </Box>
          <UseInstallments title="６回あと払いのご利用方法" data={SIXPAY} />
        </section>

        <section id={NAVS[2]}>
          <SectionMerchantGrid />
        </section>

        <section className={styles.sectionNotes}>
          <Container className={styles.container}>
            <p className={styles.title}>ご注意事項</p>
            <ul className={styles.text}>
              <li>
                キャッシュバックは、決済画面でペイディを選択した場合のみ対象です。ペイディカードの利用は対象外となります。
              </li>
              <li>
                キャンペーン終了後、6月30日または11月30日に特典分がキャッシュバックされる予定です。また予定日より遅れることもありますので、予めご了承ください。
              </li>
              <li>
                キャッシュバック付与のタイミングで、ペイディの利用が無い場合、付与日以降でペイディをご利用いただいた際に、該当
                <br />
                キャッシュバックが付与されます。あらかじめご了承ください。
              </li>
              <li>
                株式会社Paidyは本キャンペーンを、キャンペーン期間であっても予告なく変更または終了する場合がございます。
              </li>
              <li>
                不正と判断された場合やキャンセルがあった場合は、キャッシュバックが取り消される場合がございます。
              </li>
              <li>
                <p>
                  その他条件は
                  <OutboundLink
                    href="https://terms.paidy.com/cashback/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    キャッシュバック利用規約
                  </OutboundLink>
                  に準じます。
                </p>
              </li>
              <li>
                <p>
                  ペイディに関するお問い合わせは
                  <OutboundLink
                    href="https://cs.paidy.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ペイディヘルプページ
                  </OutboundLink>
                  へご連絡ください。
                </p>
              </li>
            </ul>
          </Container>
        </section>

        <JulyAppDownload />

        <SectionCampaignFooter isThemeBlack />
      </Box>
    </Layout>
  );
}
