export const MERCHANTS = [
  {
    id: 0,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-0.png'),
    destinationURL:
      'https://www.qoo10.jp/?jaehuid=2000146746&jaehuid=2000146746&gclid=CjwKCAiA0cyfBhBREiwAAtStHMaqcAtt9Zff3u4EyOez0f4RIP_--6v0v-KSOi8BaSm2V6TaWw3VVhoCWI0QAvD_BwE',
  },
  {
    id: 1,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-1.png'),
    destinationURL: 'https://fril.jp/',
  },
  {
    id: 2,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-2.png'),
    destinationURL: 'https://www.buyma.com/?af=987',
  },
  {
    id: 3,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-3.png'),
    destinationURL: 'https://shop-list.com/',
  },
  {
    id: 4,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-4.png'),
    destinationURL: 'https://www.atmos-tokyo.com/',
  },
  {
    id: 5,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-5.png'),
    destinationURL: 'https://rasik.style/',
  },
  {
    id: 6,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-6.png'),
    destinationURL:
      'https://www.2ndstreet.jp/store?utm_source=paidy.com&utm_medium=referral&utm_campaign=20230424paidyCP',
  },
  {
    id: 7,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-7.png'),
    destinationURL: 'https://shop.newbalance.jp/shop/default.aspx',
  },
  {
    id: 8,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-8.png'),
    destinationURL: 'https://www.peachjohn.co.jp/',
  },
  {
    id: 9,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-9.png'),
    destinationURL: 'https://dazzystore.com',
  },
  {
    id: 10,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-10.png'),
    destinationURL: 'https://www.urban-research.jp/',
  },
  {
    id: 11,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-11.png'),
    destinationURL: 'https://d-rw.com',
  },
  {
    id: 12,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-12.png'),
    destinationURL: 'https://dreamvs.jp/',
  },
  {
    id: 13,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-13.png'),
    destinationURL: 'https://www.junonline.jp/',
  },
  {
    id: 14,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-14.png'),
    destinationURL: 'https://nairo.jp/',
  },
  {
    id: 15,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-15.png'),
    destinationURL: 'https://www.supersports.com/ja-jp/xebio',
  },
  {
    id: 16,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-16.png'),
    destinationURL:
      'https://5-fifth.com/?utm_source=paidy_member&utm_medium=paidy&utm_term=20230424',
  },
  {
    id: 17,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-17.png'),
    destinationURL: 'https://wego.jp/',
  },
  {
    id: 18,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-18.png'),
    destinationURL: 'https://www.underarmour.co.jp/top/CSfTop.jsp',
  },
  {
    id: 19,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-19.png'),
    destinationURL: 'https://joggo.jp/',
  },
  {
    id: 20,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-20.png'),
    destinationURL: 'https://www.shipsltd.co.jp/',
  },
  {
    id: 21,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-21.png'),
    destinationURL: 'https://store.rili.tokyo/',
  },
  {
    id: 22,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-22.png'),
    destinationURL: 'https://247lingerie.co/',
  },
  {
    id: 23,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-23.png'),
    destinationURL:
      'https://punyus.jp/?utm_source=paidy&utm_medium=paidy_lp&utm_campaign=paidy05',
  },
  {
    id: 24,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-24.png'),
    destinationURL: 'https://www.y-aoyama.jp/',
  },
  {
    id: 25,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-25.png'),
    destinationURL: 'https://outlet.newbalance.jp/shop/',
  },
  {
    id: 26,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-26.png'),
    destinationURL:
      'https://greenfunding.jp/?utm_source=Paidy&utm_medium=GREENFUNDING&utm_campaign=Paidy-2304',
  },
  {
    id: 27,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-27.png'),
    destinationURL: 'https://www.paul-joe-beaute.com/',
  },
  {
    id: 28,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-28.png'),
    destinationURL:
      'https://www.qoo10.jp/?jaehuid=2000146746&jaehuid=2000146746&gclid=CjwKCAiA0cyfBhBREiwAAtStHMaqcAtt9Zff3u4EyOez0f4RIP_--6v0v-KSOi8BaSm2V6TaWw3VVhoCWI0QAvD_BwE',
  },
  {
    id: 29,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-29.png'),
    destinationURL: 'https://shop-list.com/',
  },
  {
    id: 30,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-30.png'),
    destinationURL: 'https://www.paul-joe-beaute.com/',
  },
  {
    id: 31,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-31.png'),
    destinationURL: 'https://www.junonline.jp/',
  },
  {
    id: 32,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-32.png'),
    destinationURL: 'https://travel.willer.co.jp/?mid=1458',
  },
  {
    id: 33,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-33.png'),
    destinationURL: 'https://www.sakuratravel.jp/',
  },
  {
    id: 34,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-34.png'),
    destinationURL:
      'https://www.airtrip.jp/?utm_source=paidy.com&utm_medium=web&utm_campaign=paidy_cashbackcampaign_from20230424',
  },
  {
    id: 35,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-35.png'),
    destinationURL: 'https://www.hoshinoresorts.com/',
  },
  {
    id: 36,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-36.png'),
    destinationURL: 'https://travelist.jp/',
  },
  {
    id: 37,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-37.png'),
    destinationURL: 'https://dwango-ticket.jp/',
  },
  {
    id: 38,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-38.png'),
    destinationURL: 'https://www.dmm.com/',
  },
  {
    id: 39,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-39.png'),
    destinationURL: 'https://shop.hololivepro.com/',
  },
  {
    id: 40,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-40.png'),
    destinationURL: 'https://17.live/',
  },
  {
    id: 41,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-41.png'),
    destinationURL: 'https://www.biccamera.com/bc/main/',
  },
  {
    id: 42,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-42.png'),
    destinationURL: 'https://www.yamada-denkiweb.com/',
  },
  {
    id: 43,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-43.png'),
    destinationURL: 'https://www.kojima.net/ec/index.html',
  },
  {
    id: 44,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-44.png'),
    destinationURL: 'https://dwango-ticket.jp/',
  },
  {
    id: 45,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-45.png'),
    destinationURL: 'https://www.biccamera.com/bc/main/',
  },
  {
    id: 46,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-46.png'),
    destinationURL: 'https://www.soundhouse.co.jp/',
  },
  {
    id: 47,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-47.png'),
    destinationURL: 'https://francfranc.com/',
  },
  {
    id: 48,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-48.png'),
    destinationURL: 'https://www.tansu-gen.jp/',
  },
  {
    id: 49,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-49.png'),
    destinationURL:
      'https://greenfunding.jp/?utm_source=Paidy&utm_medium=GREENFUNDING&utm_campaign=Paidy-2304',
  },
  {
    id: 50,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-50.png'),
    destinationURL: 'https://www.irisplaza.co.jp/index.php?KB=paidy_230210',
  },
  {
    id: 51,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-51.png'),
    destinationURL: 'https://www.yamada-denkiweb.com/',
  },
  {
    id: 52,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-52.png'),
    destinationURL: 'https://shop.tsukumo.co.jp/',
  },
  {
    id: 53,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-53.png'),
    destinationURL: 'https://www.kojima.net/ec/index.html',
  },
  {
    id: 54,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-54.png'),
    destinationURL: 'https://www.unico-fan.co.jp/shop/default.aspx',
  },
  {
    id: 55,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-55.png'),
    destinationURL: 'https://fril.jp/',
  },
  {
    id: 56,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-56.png'),
    destinationURL: 'https://www.buyma.com/?af=987',
  },
  {
    id: 57,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-57.png'),
    destinationURL: 'https://rasik.style/',
  },
  {
    id: 58,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-58.png'),
    destinationURL:
      'https://www.2ndstreet.jp/store?utm_source=paidy.com&utm_medium=referral&utm_campaign=20230424paidyCP',
  },
  {
    id: 59,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-59.png'),
    destinationURL: 'https://www.urban-research.jp/',
  },
  {
    id: 60,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-60.png'),
    destinationURL: 'https://kurand.jp/',
  },
  {
    id: 61,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-61.png'),
    destinationURL: 'https://www.dmm.com/',
  },
  {
    id: 62,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-62.png'),
    destinationURL: 'https://shop.hololivepro.com/',
  },
  {
    id: 63,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-63.png'),
    destinationURL: 'https://www.soundhouse.co.jp/',
  },
  {
    id: 64,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-64.png'),
    destinationURL: 'https://www.hoshinoresorts.com/',
  },
  {
    id: 65,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-65.png'),
    destinationURL: 'https://www.tansu-gen.jp/',
  },
  {
    id: 66,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-66.png'),
    destinationURL: 'https://dreamvs.jp/',
  },
  {
    id: 67,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-67.png'),
    destinationURL: 'https://www.supersports.com/ja-jp/xebio',
  },
  {
    id: 68,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-68.png'),
    destinationURL: 'https://wego.jp/',
  },
  {
    id: 69,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-69.png'),
    destinationURL: 'https://www.underarmour.co.jp/top/CSfTop.jsp',
  },
  {
    id: 70,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-70.png'),
    destinationURL: 'https://www.irisplaza.co.jp/index.php?KB=paidy_230210',
  },
  {
    id: 71,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-71.png'),
    destinationURL: 'https://shop.tsukumo.co.jp/',
  },
  {
    id: 72,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-72.png'),
    destinationURL: 'https://joggo.jp/',
  },
  {
    id: 73,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-73.png'),
    destinationURL: 'https://www.shipsltd.co.jp/',
  },
  {
    id: 74,
    imgLogo: require('src/images/merchant/campaign/202304_6pay/merchants/logos/logo-74.png'),
    destinationURL:
      'https://punyus.jp/?utm_source=paidy&utm_medium=paidy_lp&utm_campaign=paidy05',
  },
];

export const MERCHANT_GROUPED_BY_CATEGORY = [
  {
    categoryValue: 1,
    merchantIds: [...Array(28).keys()],
  },
  {
    categoryValue: 2,
    merchantIds: [28, 29, 30, 31],
  },
  {
    categoryValue: 3,
    merchantIds: [32, 33, 34, 35, 36, 37],
  },
  {
    categoryValue: 4,
    merchantIds: [38, 39, 40, 41, 42, 43, 44],
  },
  {
    categoryValue: 5,
    merchantIds: [45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59],
  },
  {
    categoryValue: 6,
    merchantIds: [60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74],
  },
];

export const TAGS = [
  { value: 0, label: 'すべて見る' },
  { value: 1, label: 'ファッション' },
  { value: 2, label: 'コスメ・カラコン' },
  { value: 3, label: 'ホテル・バス・航空券・チケット予約' },
  { value: 4, label: 'ゲーム・動画・ライブ配信など' },
  { value: 5, label: '家電・インテリア' },
  { value: 6, label: 'その他' },
];

export const FIXED_MERCHANTS = [];

export const MERCHANTS_GROUPED_BY_FIXED_TAGS = [];

export const FIXES_TAGS = [];
