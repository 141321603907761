import React from 'react';
import { Hidden } from '@material-ui/core';
import styles from './SectionMerchantGrid.module.scss';
import MerchantsGridWithAccordion from './MerchantsGridWithAccordion';

export default function SectionMerchantGrid() {
  return (
    <section className={styles.section}>
      <p className={styles.title1}>
        対象ショップで分割手数料無料*の
        <Hidden smUp>
          <br />
        </Hidden>
        ６回あと払いを初めて利用すると
      </p>
      <p className={styles.title2}>
        500円キャッシュバック
        <br />
        キャンペーン対象ショップ
      </p>
      <p className={styles.notes}>*口座振替・銀行振込のみ無料</p>
      <MerchantsGridWithAccordion notShowButtonBottom />
    </section>
  );
}
